'use client';

import dynamic from 'next/dynamic';
import {SearchSvg} from '@delorand/ui/src/svg/layout';
import {cn} from '@delorand/utils/helper/cn';
import Footer from 'c/components/layout/footer';
import TopHeader from 'c/components/layout/header/top-header';
import {useStore} from 'c/store/store';

const Header = dynamic(() => import('c/components/layout/header/header'));

export default function SearchLayout({children}: {children: React.ReactNode}) {
  const openSearch = useStore(state => state.openSearch);

  return (
    <main
      className={cn(
        'flex min-h-screen w-full flex-col',
        'justify-between pt-[90px] text-white md:pt-28 lg:pt-20'
      )}
    >
      <div className="fixed top-0 z-20 w-full justify-center">
        <TopHeader />
        <Header>
          <button
            className="mt-2 inline-flex w-full cursor-text items-center justify-center gap-3 rounded-[10px] bg-white py-1.5 hover:opacity-95 md:hidden"
            onClick={openSearch}
          >
            <SearchSvg className="fill-black/80" />
            <p className="px-2 text-black/60">Search product name, brand...</p>
          </button>
        </Header>
      </div>

      <div className="mx-auto w-full max-w-xl">{children}</div>
      <Footer />
    </main>
  );
}
